import React from 'react'
import ImageSlider from './ImageSlider'
import "../Styles/imageslider.css";

function ProjectSection() {
  return (
    <div className='imageslider'>
      <ImageSlider/>
    </div>
  )
}

export default ProjectSection
